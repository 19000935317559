// Environments:
// - production   : should send tracking
// - staging      : should send tracking (perhaps we need to add a property saying it is staging)
// - scraping     : should NOT send tracking
// - test         : should NOT send tracking
// - development  : should NOT send tracking

import { intuitBaseUrl } from "Intuit/urls";

const shouldSendTracking = ["production", "staging"].indexOf(process.env.ENV_NAME) !== -1;

export const INTUIT_ALLOWED_PAGES = {
  "/small-group/select-plan": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-quote-page",
  },

  "/small-group/select-plan-alternate": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-alternate-quote-page",
  },

  "/employer-application/coverage-options": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-coverage-options",
  },

  "/employer-application/add-employees": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-add-employees",
  },

  "/employer-application/send-employee-emails": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-send-employee-emails",
  },

  "/employer-application/employee-application-status": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-employee-application-status",
  },

  "/employer-application/documents/upload": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-documents-upload",
  },

  "/employer-application/payment": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-payment",
  },

  "/employer-application/done": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-employer-done",
  },

  "/register": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-broker-of-record-intro",
  },

  "/register/basic-information": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-broker-of-record-basic-info",
  },

  "/register/sign": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-broker-of-record-sign",
  },

  "/register/success": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-broker-of-record-done",
  },

  "/register/policy": {
    paction: "view",
    pobject: "page",
    ppageArea: "page",
    ppage: "simplyinsured-broker-of-record-done",
  },
};

export const trackIndex = (pathName: string) => {
  const matching = Object.keys(INTUIT_ALLOWED_PAGES).map((e) => pathName.endsWith(e));

  if (!matching.every((e) => !e) && pathName.startsWith(intuitBaseUrl)) {
    return matching.indexOf(true);
  }
  return -1;
};

export const recordIntuitPageView = (pathName: string, realmId: string) => {
  const index = trackIndex(pathName);
  if (index < 0) {
    return null;
  }

  if (!shouldSendTracking) {
    const trackingParams = INTUIT_ALLOWED_PAGES[Object.keys(INTUIT_ALLOWED_PAGES)[index]];

    console.log(`Intuit Tracking: ${trackingParams.paction}, ${trackingParams.pobject}, ${trackingParams.ppageArea}, ${trackingParams.ppage}, ${realmId}`);
  }

  if (window.intuitLibraryIsLoaded) {
    window.intuitLibraryIsLoaded.then(() => {
      if (shouldSendTracking) {
        const trackingParams = INTUIT_ALLOWED_PAGES[Object.keys(INTUIT_ALLOWED_PAGES)[index]];
        window.linkClick(trackingParams.paction, trackingParams.pobject, trackingParams.ppageArea, trackingParams.ppage, realmId);

        window.s.pageName = `SimplyInsured|${trackingParams.ppage}`;
        window.s.t();
      }
    });
  }
};

const INTUIT_ALLOWED_ACTIONS = {
  quote_page_add_new_employee: {
    paction: "click",
    pobject: "add-new-employee",
    ppageArea: "enter-age",
    ppage: "simplyinsured-quote-page",
  },

  enter_ages_modal_shown: {
    paction: "click",
    pobject: "add-additional-employes",
    ppageArea: "enter-age",
    ppage: "simplyinsured-quote-page",
  },

  plan_card_expand: {
    paction: "click",
    pobject: "expand-plan-card",
    ppageArea: "plan-card",
    ppage: "simplyinsured-quote-page",
  },

  filter_opened: {
    paction: "click",
    pobject: "open-filter",
    ppageArea: "filters",
    ppage: "simplyinsured-quote-page",
  },

  quote_page_click_compare: {
    paction: "click",
    pobject: "compare",
    ppageArea: "plan-card",
    ppage: "simplyinsured-quote-page",
  },

  quote_page_click_compare_plans: {
    paction: "click",
    pobject: "compare",
    ppageArea: "quote-page-bottom",
    ppage: "simplyinsured-quote-page",
  },

  quote_page_click_more_detail: {
    paction: "click",
    pobject: "more-detail",
    ppageArea: "plan-card",
    ppage: "simplyinsured-quote-page",
  },

  checked_outcart_add_dental: {
    paction: "click",
    pobject: "add-dental",
    ppageArea: "shopping-cart",
    ppage: "simplyinsured-coverage-options",
  },

  checked_outcart_select_dental: {
    paction: "click",
    pobject: "select-dental",
    ppageArea: "dental-plan-popup",
    ppage: "simplyinsured-coverage-options",
  },

  checked_outcart_add_vision: {
    paction: "click",
    pobject: "add-vision",
    ppageArea: "shopping-cart",
    ppage: "simplyinsured-coverage-options",
  },

  checked_outcart_select_vision: {
    paction: "click",
    pobject: "select-vision",
    ppageArea: "vision-plan-popup",
    ppage: "simplyinsured-coverage-options",
  },

  step_form_next_clicked: {
    paction: "click",
    pobject: "save-and-continue",
    ppageArea: "navigation-controls",
    ppage: "simplyinsured-coverage-options",
  },

  add_employees_save_clicked: {
    paction: "click",
    pobject: "save-and-continue",
    ppageArea: "navigation-controls",
    ppage: "simplyinsured-add-employees",
  },

  add_employees_add_owner_or_contractor: {
    paction: "click",
    pobject: "add-owner-or-contractor",
    ppageArea: "add-employee-field",
    ppage: "simplyinsured-add-employees",
  },

  review_employee_emails_save_clicked: {
    paction: "click",
    pobject: "save-and-continue",
    ppageArea: "navigation-controls",
    ppage: "simplyinsured-send-employee-emails",
  },

  review_employee_application_status_save_clicked: {
    paction: "click",
    pobject: "save-and-continue",
    ppageArea: "navigation-controls",
    ppage: "simplyinsured-employee-application-status",
  },

  document_upload_choose_file_clicked: {
    paction: "click",
    pobject: "choose-file",
    ppageArea: "document-upload-picker",
    ppage: "simplyinsured-documents-upload",
  },

  document_upload_how_do_I_get_this_clicked: {
    paction: "click",
    pobject: "how-do-I-get-this",
    ppageArea: "document-upload-picker",
    ppage: "simplyinsured-documents-upload",
  },

  document_upload_choose_file_done_clicked: {
    paction: "click",
    pobject: "done",
    ppageArea: "document-upload-picker-popup",
    ppage: "simplyinsured-documents-upload",
  },

  document_upload_choose_file_close_clicked: {
    paction: "click",
    pobject: "close",
    ppageArea: "document-upload-picker-popup",
    ppage: "simplyinsured-documents-upload",
  },

  document_upload_save_clicked: {
    paction: "click",
    pobject: "save-and-continue",
    ppageArea: "navigation-controls",
    ppage: "simplyinsured-documents-upload",
  },

  payment_information_save_clicked: {
    paction: "click",
    pobject: "save-and-continue",
    ppageArea: "navigation-controls",
    ppage: "simplyinsured-payment",
  },
};

export const recordIntuitAction = (actionName: string, realmId: string, properties: any) => {
  // Need to separate the two different checkout buttons on the shopping cart for Intuit.
  if (actionName == "checked_out") {
    actionName += properties.location;
  }

  if (!Object.keys(INTUIT_ALLOWED_ACTIONS).includes(actionName)) {
    return null;
  }

  if (!shouldSendTracking) {
    const trackingParams = INTUIT_ALLOWED_ACTIONS[actionName];
    console.log(`Intuit Tracking: ${trackingParams.paction}, ${trackingParams.pobject}, ${trackingParams.ppageArea}, ${trackingParams.ppage}, ${realmId}`);
  }

  if (window.intuitLibraryIsLoaded) {
    window.intuitLibraryIsLoaded.then(() => {
      if (shouldSendTracking) {
        const trackingParams = INTUIT_ALLOWED_ACTIONS[actionName];
        window.linkClick(trackingParams.paction, trackingParams.pobject, trackingParams.ppageArea, trackingParams.ppage, realmId);
      }
    });
  }
};

const INTUIT_TRACKED_DATA = {
  quote_page_filter: {
    paction: "view",
    ppageArea: "plan-filter",
    ppage: "simplyinsured-quote-page",
  },

  "quote_page/ADD_PLAN_TO_CART": {
    paction: "click",
    ppageArea: "plan-card",
    ppage: "simplyinsured-quote-page",
  },

  focus_application_field: {
    paction: "focus",
    ppageArea: "application-form",
    ppage: "simplyinsured-employer-application",
  },

  validate_application_field: {
    paction: "validate",
    ppageArea: "application-form",
    ppage: "simplyinsured-employer-application",
  },

  step_form_update_coverage_date: {
    paction: "click",
    ppageArea: "application-form",
    ppage: "simplyinsured-company-info",
  },

  add_new_employee_data: {
    paction: "click",
    ppageArea: "add-employee-modal",
    ppage: "simplyinsured-add-employees",
  },

  document_upload_num_document: {
    paction: "view",
    ppageArea: "document-upload-picker",
    ppage: "simplyinsured-documents-upload",
  },

  add_employee_decline_benefit: {
    paction: "click",
    ppageArea: "add-employee-table",
    ppage: "simplyinsured-add-employees",
  },
};

export const recordIntuitData = (actionName: string, realmId: string, properties: any) => {
  if (!Object.keys(INTUIT_TRACKED_DATA).includes(actionName)) {
    return null;
  }

  if (!shouldSendTracking) {
    const trackingParams = INTUIT_TRACKED_DATA[actionName];
    console.log(`Intuit Tracking: ${trackingParams.paction}, ${trackingParams.pobject}, ${trackingParams.ppageArea}, ${trackingParams.ppage}, ${realmId}`);
  }

  if (window.intuitLibraryIsLoaded) {
    window.intuitLibraryIsLoaded.then(() => {
      if (shouldSendTracking) {
        const trackingParams = INTUIT_TRACKED_DATA[actionName];
        let stringObject = "";

        if (actionName == "quote_page/ADD_PLAN_TO_CART") {
          stringObject += `plan_id: ${properties.plan_id}`;
        } else {
          Object.keys(properties).forEach((p) => {
            stringObject += `${p}: ${properties[p]};`;
          });
        }

        window.linkClick(trackingParams.paction, stringObject, trackingParams.ppageArea, trackingParams.ppage, realmId);
      }
    });
  }
};
